.main {
  width: 100%;
}
.main .newsMain {
  max-width: 100%;
  padding: 10px;
}
.main .newsMain .newsDetailBox {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 55px;
}
.main .newsMain .newsDetailBox .newsDetail {
  padding-top: 38px;
}
.main .newsMain .newsDetailBox .newsDetail .title {
  width: 100%;
  text-align: center;
}
.main .newsMain .newsDetailBox .newsDetail .title h3 {
  font-weight: bold;
  color: #0065af !important;
  font-size: 32px;
  margin-bottom: 38px;
}
.main .newsMain .newsDetailBox .newsDetail .newsCon {
  width: 100%;
}
.main .newsMain .newsDetailBox .newsDetail .newsCon .mk-text-block p {
  font-size: 14px;
  color: #5e5e5e;
  margin-bottom: 20px;
  line-height: 1.66em;
}
.main .newsMain .newsDetailBox .newsDetail .newsCon .mk-text-block p strong {
  color: #0065af;
}
.main .newsMain .newsDetailBox .newsDetail .newsCon .mk-text-block ol {
  margin-bottom: 1em;
  margin-left: 2.35em;
  list-style-position: outside;
  list-style-type: decimal;
}
.main .newsMain .newsDetailBox .newsDetail .newsCon .mk-text-block ol li {
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 14px;
  color: #5e5e5e;
  margin-bottom: 5px;
  line-height: 1.66em;
}
.main .newsMain .sls {
  max-width: 1000px;
  margin: 0 auto;
  padding: 55px 0;
}
.main .newsMain .sls .wa {
  font-size: 28px;
  display: block;
  margin-bottom: 20px;
  font-weight: bold;
  width: 100%;
  color: #5e5e5e;
  text-align: center;
}
.main .newsMain .sls span {
  font-size: 220px;
  line-height: 100%;
  display: block;
  font-weight: 300;
  padding-bottom: 20px;
  margin-bottom: 20px;
  color: #0065af;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 880px) {
  .main .newsMain .sls span {
    font-size: 100px;
  }
}
@media screen and (max-width: 680px) {
  .main .newsMain .sls span {
    font-size: 80px;
  }
}
@media screen and (max-width: 480px) {
  .main .newsMain .sls span {
    font-size: 60px;
  }
}
